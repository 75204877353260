import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationGuard } from "./components/authentication-guard";
import { MainApp } from './pages/main-app';
import { HomePage } from './pages/home-page';
import { Settings } from './pages/settings';

function App() {
    const { isLoading, isAuthenticated } = useAuth0();
    if (isLoading) {
        return (
            <div>
                <h1>LOADING...</h1>
            </div>
        );
    }

    if (!isAuthenticated) {
        console.log('user is NOT authenticated');
        return (
            <Routes>
                <Route
                    path='/'
                    element={<HomePage />}
                />

                {/* TODO - remove this route when we have a sign up button */}
                <Route
                    path='/settings'
                    element={<AuthenticationGuard component={Settings} />}
                />
                <Route path="*" element={<HomePage />} />
            </Routes>
        );
    } else {
        console.log('user IS authenticated');
        return (
            <Routes>
                <Route
                    path='/'
                    element={<AuthenticationGuard component={MainApp} />}
                />
                <Route
                    path='/settings'
                    element={<AuthenticationGuard component={Settings} />}
                />
                <Route path="*" element={<h1>404</h1>} />
            </Routes>
        );
    }
}

export default App;
