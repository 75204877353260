import React from 'react';
import NavBar from './components/nav-bar';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom'
import clsx from 'clsx';

export const PageLayout = ({
                               children
                           }: { children: React.ReactNode}) => {
    const { isAuthenticated } = useAuth0();
    const pathname = useLocation();
    const pageName = pathname?.pathname.split('/').pop();
    const pageTitle = pageName ? (pageName.charAt(0).toUpperCase() + pageName.slice(1)) : 'Home';

    return (
        <div className='min-h-full'>
            {isAuthenticated && (<NavBar />)}
            {isAuthenticated && pageTitle && (
                <header className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-semibold tracking-tight text-gray-900">{pageTitle}</h1>
                    </div>
                </header>
            )}
                <div className={clsx(!isAuthenticated ? '' : 'mx-auto max-w-7xl py-6 sm:px-6 lg:px-8')}>{children}</div>
        </div>
    );
};
