import axios from 'axios';
import React, { useState, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { DocumentTextIcon } from '@heroicons/react/24/solid'


export const MainApp = () => {
    const ref = useRef();
    const baseApiURL = process.env.REACT_APP_API_ENDPOINT;
    const { getAccessTokenSilently } = useAuth0();
    const [file, setFile] = useState();
    const [fileText, setFileText] = useState();

    function handleFileChange(event: any) {
        setFile(event.target.files[0]);
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        // Step 1 - Get a presigned URL from our API to upload our file to:
        const accessToken = await getAccessTokenSilently();

        const uploadUrl = await axios.post(`${baseApiURL}/getUploadUrl`, {
            // @ts-ignore
            filename: file!.name
        }, {
            headers: {
                Authorization: `${accessToken}`
            }
        });

        const url = uploadUrl.data;
        const formData = new FormData();
        formData.append('file', file!);
        // @ts-ignore
        formData.append('fileName', file!.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        await axios.put(url, formData, config);
        const serverOutput = await axios.post(`${baseApiURL}/extract`, {
            // @ts-ignore
            filename: file!.name
        }, {
            headers: {
                Authorization: `${accessToken}`
            }
        });

        setFileText(serverOutput.data);
        // @ts-ignore
        ref.current.value = "";
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='space-y-12'>
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div className="col-span-full">
                            <label htmlFor="file-upload" className="block text-sm font-medium leading-6 text-gray-900">
                                File Upload
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                    <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none hover:text-indigo-500"
                                        >
                                            <span>Upload a file</span>
                                            <input ref={ref} id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">File size up to 10MB</p>
                                    {file && (
                                    <button className='mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' type='submit'>Process</button>
                                    )}
                                </div>
                            </div>
                            {fileText && (
                                <div>
                                    <h1>File contents:</h1>
                                    <pre>{fileText}</pre>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>


        // <div className="flex h-screen">
        //     <div className="m-auto">
        //         <form onSubmit={handleSubmit}>
        //             <div>
        //                 <input
        //                     type='file'
        //                     ref={ref}
        //                     onChange={handleFileChange}
        //                     className='text-slate-600 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-700'
        //                 />
        //             </div>
        //
        //             {file && (
        //                 <button className='mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' type='submit'>Upload & Process</button>
        //             )}
        //         </form>
        //         {fileText && (
        //             <>
        //                 <h1>File contents:</h1>
        //                 <pre>{fileText}</pre>
        //             </>
        //         )}
        //     </div>
        // </div>
    );
}
