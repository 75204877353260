import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div>
                <h1>LOADING...</h1>
            </div>
        ),
    });

    return <Component />;
};
